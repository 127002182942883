import ImageMan1 from '../assets/img/man.jpg';
import ImageMan2 from '../assets/img/man.jpg';
import ImageMan3 from '../assets/img/man.jpg';
import ImageMan4 from '../assets/img/man.jpg';
import ImageMan5 from '../assets/img/man.jpg';
import ImageMan6 from '../assets/img/man.jpg';

const ManImages = [
  ImageMan1,
  ImageMan2,
  ImageMan3,
  ImageMan4,
  ImageMan5,
  ImageMan6
];

const MockData = {

  menuItems: [
    {
      title: 'Карта',
      tag: 'map'
    },
    {
      title: 'Список',
      tag: 'list'
    },
  ],

  popularAgents: [

  ],

	// Agents for full list
	agents: [
		{
			avatar: ManImages[0],
      agency: 'ООО Лучшее Агенство',
      name: 'Макаров Петр',
      phone: 79830705853,
      rating: 4.9,
      reviews: 1,
      location: [0,0],
      link: 'https://reagent.pro',
      review: {
        date: '09/29/2021',
        comment: 'Благодаря Владимиру мы с супругом стали инвесторами в Сочинскую Недвижимость и увеличили свой капитал в несколько раз! Он четко подбирает...'
      }
		},
    {
			avatar: ManImages[1],
      agency: 'ООО Лучшее Агенство',
      name: 'Макаров Петр',
      phone: 79830705853,
      rating: 4.9,
      reviews: 1,
      location: [0,0],
      link: 'https://reagent.pro',
      review: {
        date: '09/29/2021',
        comment: 'Благодаря Владимиру мы с супругом стали инвесторами в Сочинскую Недвижимость и увеличили свой капитал в несколько раз! Он четко подбирает...'
      }
		},
    {
			avatar: ManImages[2],
      agency: 'ООО Лучшее Агенство',
      name: 'Макаров Петр',
      phone: 79830705853,
      rating: 4.9,
      reviews: 1,
      location: [0,0],
      link: 'https://reagent.pro',
      review: {
        date: '09/29/2021',
        comment: 'Благодаря Владимиру мы с супругом стали инвесторами в Сочинскую Недвижимость и увеличили свой капитал в несколько раз! Он четко подбирает...'
      }
		},
    {
			avatar: ManImages[3],
      agency: 'ООО Лучшее Агенство',
      name: 'Макаров Петр',
      phone: 79830705853,
      rating: 4.9,
      reviews: 1,
      location: [0,0],
      link: 'https://reagent.pro',
      review: {
        date: '09/29/2021',
        comment: 'Благодаря Владимиру мы с супругом стали инвесторами в Сочинскую Недвижимость и увеличили свой капитал в несколько раз! Он четко подбирает...'
      }
		},
    {
			avatar: ManImages[4],
      agency: 'ООО Лучшее Агенство',
      name: 'Макаров Петр',
      phone: 79830705853,
      rating: 4.9,
      reviews: 1,
      location: [0,0],
      link: 'https://reagent.pro',
      review: {
        date: '09/29/2021',
        comment: 'Благодаря Владимиру мы с супругом стали инвесторами в Сочинскую Недвижимость и увеличили свой капитал в несколько раз! Он четко подбирает...'
      }
		},
    {
			avatar: ManImages[5],
      agency: 'ООО Лучшее Агенство',
      name: 'Макаров Петр',
      phone: 79830705853,
      rating: 4.9,
      reviews: 1,
      location: [0,0],
      link: 'https://reagent.pro',
      review: {
        date: '09/29/2021',
        comment: 'Благодаря Владимиру мы с супругом стали инвесторами в Сочинскую Недвижимость и увеличили свой капитал в несколько раз! Он четко подбирает...'
      }
		},
	],

	// Search Tasks block
	search: {
		title: 'Выберите какую задачу вы хотите решить',
		bottomText: 'Powered by Reagent',
		reducedSize: false,
		input: {
				placeholder: 'Начните вводить название...',
		},
		btn: {
				text: 'Отправить оффер'
		}
	}
};

export default MockData;