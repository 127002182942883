import style from './style.module.less';

import FormSelect from '../../Form/Select';
import Option from '../../Form/Select/Option';
import FormButton from '../../Form/Button';
import { useState } from 'react';
import { setGlobalField } from '../../../helpers';

function StepCity(props) {

  const {
    items = [
      {value: 1, title: 'Сочи'},
      {value: 2, title: 'Москва'},
    ],
    onSubmit = (e) => {},
    onChange = (e) => {}
  } = props;

  const [city, setCity] = useState(items[0].title);

  function handleClickButton(e) {

    setGlobalField('searchTask_city', city);

    if( typeof onSubmit === 'function' ) {
      return onSubmit(e);
    }
  }

  function handleChangeSelect(e) {

    setCity(e.target.value);
    setGlobalField('searchTask_city', e.target.value);

    if( typeof onChange === 'function' ) {
      return onChange(e);
    }
  }

  return (
    <>
      <h2>Запрос по какому городу?</h2>

      <div className={style.form}>
        <FormSelect name="city" className={style.select__field} wrapperClassName={style.select__wrapper} onChange={handleChangeSelect} value={city}>
          {items.map((item) => (<Option value={item.title} key={item.title}>{item.title}</Option>))}
        </FormSelect>

        <FormButton onClick={handleClickButton} className={style.btn__form} isOuter={true}>Далее</FormButton>
      </div>
    </>
  );
}

export default StepCity;