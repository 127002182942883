// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3t1PTEze2N9aGJ50443lfs {\n  display: flex;\n  width: 100%;\n}\n.I3dwOZcRYD2O4-hkBK-o1 {\n  display: flex;\n  width: 100%;\n  min-height: 400px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Entities/EntitiesMap/style.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AAEA;EACI,aAAA;EACA,WAAA;EACA,iBAAA;AAAJ","sourcesContent":[".container {\n    display: flex;\n    width: 100%;\n}\n\n.map {\n    display: flex;\n    width: 100%;\n    min-height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3t1PTEze2N9aGJ50443lfs",
	"map": "I3dwOZcRYD2O4-hkBK-o1"
};
export default ___CSS_LOADER_EXPORT___;
