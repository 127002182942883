import style from './style.module.less';

import PopularEntitieItem from './Item';
import { Link } from 'react-router-dom';
import Section from '../Section';
import { useLayoutEffect, useRef, useState } from 'react';
import { getEntities } from '../Entities';
import { useDispatch } from 'react-redux';
import { setModalContent, setModalIsVisible } from '../Modal/modal.slice';
import EntitiesModal from '../Entities/EntitiesModal';

/**
 * Popular Entities Component
 * 
 * @param {object} props 
 * @returns 
 */
const PopularEntities = (props) => {

    const {
        title = 'Популярные объекты из закрытой профессиональной базы',
        btn = {
            text: 'Посмотреть все объекты',
            link: '/'
        }
    } = props;

    let [items, setItems] = useState([]);
    const firstComponentUpdate = useRef(true);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        if (firstComponentUpdate.current === true) {
            firstComponentUpdate.current = false;

            getEntities().then(data => {
                if (data?.ads) {
                    return setItems(data?.ads);
                }
            });
        }
    });

    if (typeof items !== 'object') {
        return (<></>);
    }

    if (typeof items.length !== 'number') {
        return (<></>);
    }

    if (items.length <= 0) {
        return (<></>);
    }

    if (items.length > 6) {
        items = items.slice(0, 6);
    }

    // Modal
    const handleFullObjectsClick = (e) => {
        e.preventDefault();

        dispatch(setModalIsVisible(true));
        dispatch(setModalContent((
            <EntitiesModal />
        )));

        return;
    }

    // Render
    return (
        <Section className={style.popular} containerClassName={style.container}>

            <h2>{title}</h2>

            <div className={style.row}>

                {items.map((item) => (
                    <PopularEntitieItem data={item} key={item.label} />
                ))}

            </div>

            <Link onClick={handleFullObjectsClick} to={btn.link}>
                {btn.text}
            </Link>

        </Section>
    );
}

export default PopularEntities;