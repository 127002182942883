import { numberFormatter } from '../../../helpers';
import style from './style.module.less';
import NoImage from '../../../assets/img/no_foto.png';
import VideoIcon from '../../../assets/img/videoIco.png';
import VRIcon from '../../../assets/img/vrTour.svg';

/**
 * Entity component for Entities row
 *
 * @param {object} props
 * @returns
 */
const Entity = (props) => {
  const {
    className = '',
    data = {}
  } = props;

  const {
    city,
    region,
    street,
    price,
    www_link,
    type,
    area,
    photos,
    home_number
  } = data;

  const formattedPrice = numberFormatter(price);

  let image = NoImage;
  let resultType = 'Недвижимость';
  let address = '';

  switch(type) {
    case 'House': resultType = 'Дом'; break;
    case 'Flat': resultType = 'Квартира'; break;
    default: address = ''; break;
  }

  // Getting photo image
  if (photos?.length >= 1) {
    image = photos[0].medium;
  }

  // Street
  if (street && street?.length >= 2) {
    address += street;

    if (home_number && home_number >= 1) {
      address += `, д. ${home_number}`;
    }
  }

  // Render
  return (
    <a href={www_link} target="_blank" rel="referrer" className={[style.entity, className].join(' ')}>

      <div className={style.image}>
        <img src={image} alt={`${city?.name}, ${region?.name}`} />

        {data?.video && data.video?.length >= 1 && (
          <a href={www_link} className={style.vrTour}>
            <img className={style.tourImage} src={VideoIcon} alt="video" />
            <span>Посмотреть видео</span>
          </a>
        )}

        {data?.vr_3d_tour && data.vr_3d_tour?.length >= 1 && (
          <a href={www_link} className={style.vrTour}>
            <img className={style.tourImage} src={VRIcon} alt="video" />
            <span>Посмотреть видео</span>
          </a>
        )}

        <span className={style.city}>{city?.name}</span>
      </div>

      <div className={style.body}>

        <div className={style.row}>
          <div className={style.character}>
           {city?.name}, {region?.name}
          </div>
          <div className={[style.character, style.character_price].join(' ')}>
            {formattedPrice} ₽
          </div>
        </div>

        <div className={style.row}>
          <div className={style.character}>
            {address}
          </div>
        </div>

        <div className={style.row}>
          <div className={style.character}>
            {resultType} {area} м²
          </div>
          <div className={[style.character, style.character_link].join(' ')}>
            <a href={www_link} target="_blank" rel="referrer">Подробнее</a>
          </div>
        </div>

      </div>

    </a>
  );
}

export default Entity;
