// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1rjsH6XOW4N_ImJY1avlX- {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  width: 100%;\n  border-radius: 5px;\n  background-color: #F2FAFF;\n  padding: 27px 44px;\n  margin-bottom: 15px;\n}\n._2IFigcZti41edh0awFXD9C {\n  margin-right: 6%;\n}\n._3bCSsXv1bXClGYFBgx5jaC {\n  display: flex;\n  flex-direction: column;\n}\n._3bCSsXv1bXClGYFBgx5jaC span {\n  margin-bottom: 5px;\n}\n._3bCSsXv1bXClGYFBgx5jaC ._2qJ863FgnsZz1DUn8JsxpW {\n  font-weight: 600;\n  color: #006EF9;\n}\n", "",{"version":3,"sources":["webpack://src/components/Entities/EntityMessage/style.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,aAAA;EACA,sBAAA;AADJ;AADA;EAKQ,kBAAA;AADR;AAJA;EASQ,gBAAA;EACA,cAAA;AAFR","sourcesContent":[".container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    width: 100%;\n    border-radius: 5px;\n    background-color: #F2FAFF;\n    padding: 27px 44px;\n    margin-bottom: 15px;\n}\n\n.icon {\n    margin-right: 6%;\n}\n\n.row {\n    display: flex;\n    flex-direction: column;\n\n    span {\n        margin-bottom: 5px;\n    }\n\n    .bolder {\n        font-weight: 600;\n        color: #006EF9;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1rjsH6XOW4N_ImJY1avlX-",
	"icon": "_2IFigcZti41edh0awFXD9C",
	"row": "_3bCSsXv1bXClGYFBgx5jaC",
	"bolder": "_2qJ863FgnsZz1DUn8JsxpW"
};
export default ___CSS_LOADER_EXPORT___;
