import style from './style.module.less';
import Section from '../Section';
import CentreLogo from '../../assets/img/logo.svg';
import ImageAppStore from '../../assets/img/getAppStore.svg';
import ImageGooglePlay from '../../assets/img/getGooglePlay.svg';
import ImageAppGallery from '../../assets/img/getAppGallery.svg';


/**
 * Footer Component
 *
 * @returns
 */
const Footer = () => {
    const webUrl = process.env.REACT_APP_WEB_URL || 'https://web.reagent.pro'
    return (
        <>
            <Section className={style.footer} containerClassName={style.container}>

                    <div className={style.row}>

                        <div className={style.subrow}>
                            <div className={style.logo}>
                                <img src={CentreLogo} alt="Centre Realty" />
                            </div>
                        </div>


                        <div className={style.menu}>

                            <div className={style.menu_item}>
                                <div className={style.menu_headline}>
                                    <h2>Агентам</h2>
                                </div>
                                <div className={style.menu_content}>
                                    <ul>
                                        <li>
                                            <a className={style.reagent_button} href={webUrl}>Цифровой профиль</a>
                                        </li>
                                        <li>
                                            <a className={style.reagent_button} href={webUrl}>Аттестация</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className={style.menu_item}>
                                <div className={style.menu_headline}>
                                    <h2>Компания</h2>
                                </div>

                                <div className={style.menu_content}>
                                    <div className={style.menu_property}>
                                        <p>© 2015–2023 ©Reagent.pro</p>
                                    </div>
                                    <div className={style.menu_property}>
                                        <p>Все права защищены.</p>
                                    </div>
                                    <div className={style.menu_property}>
                                        <p>ООО «Реагент.про»</p>
                                    </div>
                                    <div className={style.menu_property}>
                                        <p>ИНН 2320232357</p>
                                    </div>

                                    <div className={style.add_distance}></div>

                                    <div className={style.menu_property_black}>
                                        <p>info@reagent.pro</p>
                                    </div>
                                    <div className={style.menu_property_black}>
                                        <p>support@reagent.pro</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className={style.apps}>
                        <div className={style.application}>
                            <a href="https://apps.apple.com/ru/app/getagent/id1087001957" target="_blank" rel="noreferrer"><img src={ImageAppStore} alt="Getting from App Store" /></a>
                        </div>
                        <div className={style.application}>
                            <a href="https://play.google.com/store/apps/details?id=pro.reagent" target="_blank" rel="noreferrer"><img src={ImageGooglePlay} alt="Getting from Google Play" /></a>
                        </div>
                        <div className={style.application}>
                            <a href="https://consumer.huawei.com/ru/mobileservices/appgallery/" target="_blank" rel="noreferrer"><img src={ImageAppGallery} alt="Getting from App Gallery" /></a>
                        </div>
                    </div>
            </Section>

            <div className={style.bottom_line}>
                Использование сайта означает согласие с <a href="/">условиями пользования</a> и <a href="/">политикой конфиденциальности</a> и на обработку Ваших <a href="/">персональныйх данных</a>
            </div>
        </>
    );
}

export default Footer;
