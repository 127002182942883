// Component styles
import '../../assets/fonts/proximaNova/font.less';
import './App.less';

// Import componets
import Header from '../Header';
import Footer from '../Footer';

// Import pages
import Home from '../../pages/Home';
import Agents from '../../pages/Agents';
import FullObjects from '../../pages/FullObjects';
import Objects from '../../pages/Objects';

// Import Router
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AgentsSelected from '../AgentsSelected';
import Modal from '../Modal';

const App = () => {

  // Create global storage array
  if (!window.globalStorage || typeof window.globalStorage !== 'object' || typeof window.globalStorage.length !== 'number') {
    window.globalStorage = [];
  }

  // For sending requests
  if( typeof window.isSendingRequest !== 'boolean' ) {
    window.isSendingRequest = true;
  }

  return (
    <Router>

      <Header />

      <Switch>
        <Route path="/objects/other">
          <Objects />
        </Route>

        <Route path="/objects/full">
          <FullObjects />
        </Route>

        <Route path="/objects">
          <FullObjects />
        </Route>

        <Route path="/agents">
          <Agents />
        </Route>

        <Route path="/">
          <Home />
        </Route>
      </Switch>

      <Footer />

      <AgentsSelected />

      <Modal />

    </Router>
  );
}

export default App;
