export function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

/**
 * Scrolling to element
 * 
 * @param {string} selector 
 * @returns bool
 */
export function scrollToDOM(selector = '') {

    if (!selector || (typeof selector === 'string' && selector.length <= 0)) {
        return false;
    }

    const element = document.querySelector(selector);

    if (!element || typeof element !== 'object') {
        return false;
    }

    const scrollPositionY = element.offsetTop - 30;
    window.scrollTo({
        top: scrollPositionY,
        behavior: 'smooth'
    });

    return true;
}

/**
 * For subscribe on change field
 * 
 * @param {string} name 
 * @param {function} fn 
 * @returns 
 */
export function subscribeField(name, fn) {

    if (typeof name !== 'string' || (typeof name === 'string' && name.length <= 0)) {
        return false;
    }

    if (!window.fieldSubscribes || typeof window.fieldSubscribes !== 'object') {
        window.fieldSubscribes = [];
    }

    if (!window.fieldSubscribes[name] || typeof window.fieldSubscribes[name] !== 'object') {
        window.fieldSubscribes[name] = [];
    }

    window.fieldSubscribes[name].push(fn);

    return true;
}

/**
 * Function for formatted number
 * 
 * @param {number} num 
 * @param {number} decimalCount 
 * @param {string} decimal 
 * @param {string} thousands 
 * @returns 
 */
export function numberFormatter(amount = 0, decimalCount = 0, decimal = ".", thousands = " ") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e);
    }
};

/**
 * For save field in local storage
 * 
 * @param {string} name 
 * @param {any} value 
 * @returns
 */
export function setGlobalField(name, value) {

    if (typeof name !== 'string' || (typeof name !== 'string' && name.length <= 0)) {
        return false;
    }

    const oldValue = getGlobalField(name);
    const trueValue = value;
    let type = typeof value;

    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }

    // Create global storage array
    if (!window.globalStorage || typeof window.globalStorage !== 'object' || typeof window.globalStorage.length !== 'number') {
        window.globalStorage = [];
    }

    // Save field in local storage
    window.globalStorage[name] = value;
    window.globalStorage[`${name}__type`] = type;

    // Use callbacks
    if (window.fieldSubscribes && typeof window.fieldSubscribes === 'object') {
        if (window.fieldSubscribes[name] && typeof window.fieldSubscribes[name] === 'object' && window.fieldSubscribes[name].length >= 1) {
            window.fieldSubscribes[name].forEach(subscribeCallback => {
                subscribeCallback(trueValue, oldValue);
            });
        }
    }

    return true;
}

/**
 * For getting global field
 * 
 * @param {string} name 
 * @returns 
 */
export function getGlobalField(name) {

    if (typeof name !== 'string' || (typeof name !== 'string' && name.length <= 0)) {
        return null;
    }

    // Create global storage array
    if (!window.globalStorage || typeof window.globalStorage !== 'object' || typeof window.globalStorage.length !== 'number') {
        window.globalStorage = [];
    }

    const typeName = `${name}__type`;
    const type = window.globalStorage[typeName];
    const value = window.globalStorage[name];

    let result = '';

    switch (type) {
        case 'number':
            result = parseInt(value);
            break;
        case 'boolean':
            result = (value === 'true');
            break;
        case 'object':
            result = JSON.parse(value);
            break;
        default:
            result = value;
    }

    return result;
}

export function dateFormatted(date) {
    
    let result = '';
    let innerDate = new Date();

    if( typeof date === 'string' ) {
        innerDate = new Date(date);
    }

    const year = innerDate.getFullYear();
    let month = innerDate.getMonth() + 1;
    let day = innerDate.getDate();

    if( month <= 9 ) {
        month = `0${month}`;
    }

    if( day <= 9 ) {
        day = `0${day}`;
    }
    
    result = `${day}.${month}.${year}`;

    return result;
}

export const findCenterFromEntities = (entities) => {
    
    let mapCenter = {
        lat: 0,
        lng: 0,
        count: 0
    };

    entities.forEach(entity => {
        if( entity.latitude && entity.longitude ) {
            mapCenter.lat += entity.latitude;
            mapCenter.lng += entity.longitude;
            ++mapCenter.count;
        }
    });

    if( mapCenter.count >= 1) {
        mapCenter.lat /= mapCenter.count;
        mapCenter.lng /= mapCenter.count;
    } else {
        mapCenter.lat = 55.75;
        mapCenter.lng = 37.57;
    }

    window.entites = entities;

    return mapCenter;
}

export default declOfNum;