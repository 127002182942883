'use strict';

import style from './style.module.less';

import Section from '../Section';
import LinkButton from '../LinkButton';
import { Link } from "react-router-dom";

/**
 * Video Component
 *
 * @param {object} props
 * @returns
 */
const Video = (props) => {

  const {
    className = '',
    app = '',
    title = 'Найдите объект вашей мечты',
    description = 'Агенты обмениваются реальной информацией об объектах только в профессианальной базе, без фейков и уток, вы можете найти свой вариант из всех объектов рынка, для этого просто выберите себе агента проводника.',
    image = '',
    startBtn = {
      text: 'Начать',
      link: '#go'
    },
    videoBtn = {
      text: 'Посмотреть видео',
      link: '#go'
    }
  } = props;

  // Render
  return (
    <Section className={[style.video, className].join(' ')}>

      <div className={style.row}>
        <div className={[style.col, style.col__left].join(' ')}>

          <h1>{app}</h1>
          <h2>{title}</h2>
          <p>{description}</p>

          <div className={style.buttons}>

            <LinkButton
              isDark={true}
              to="#go"
              rel="noreferrer"
            >
              {startBtn.text}
            </LinkButton>

            <a href="#go" className={style.button} rel="noreferrer">
              <i className={[style.icon, style.icon__video].join(' ')}></i>
              {videoBtn.text}
            </a>

          </div>

        </div>
        <div className={[style.col, style.col__right].join(' ')}>

          <img
            className={style.image}
            src={image}
            alt={title}
          />

        </div>
      </div>

    </Section>
  );
}

export default Video;
