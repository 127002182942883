import style from './style.module.less';

/**
 * Tabs component
 * 
 * @param {object} props 
 * @returns 
 */
const Tabs = (props) => {
  const {
    children = '',
    selectedTab = 0
  } = props;

  // Render
  return (
    <div className={style.tabs}>
      {children.map((child, index) => {

        let classList = [style.tab];

        if( index === selectedTab ) {
          classList.push(style.active);
        }

        return (
          <div className={classList.join(' ')} key={`${index}_${classList}`}>
            {child}
          </div>
        );
      })}
    </div>
  );
}

export default Tabs;