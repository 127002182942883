import React, {useState} from 'react';

import style from './style.module.less';

const FormText = (props) => {
  
  
  const {
    className = '',
    name = '',
    placeholder = '',
    value = '',
    type = 'text',
    onChange = (e) => {}
  } = props;
  
  let [valueSearchTask, setValueSearchTask] = useState(value);

  const handleInputChange = (e) => {
    onChange(e);
    setValueSearchTask(e.target.value);
  }

  // Render
  return (
    <input 
				className={[style.field, className].join(' ')}
				name={name}
				placeholder={placeholder}
				type={type}
				value={valueSearchTask}
				onChange={handleInputChange}
			/>
  );
}

export default FormText;