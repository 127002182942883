// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/camera.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n *\n * Animations\n *\n **/\n._2Cw6I-zdwSelXNzEqTqtpr {\n  width: 22px;\n  height: 22px;\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n._2lyDID4IyDvmQhAAKcD7EY {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n._3VfEhnoobj7mvllaW0psg- {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 60px;\n}\n._3VfEhnoobj7mvllaW0psg- ._3PfxwkCvvXBVfuh1VdIKqe {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  max-width: 1152px;\n  width: 100%;\n  padding: 20px 10px;\n}\n._3VfEhnoobj7mvllaW0psg- ._2l4k-6sWn1JJ0L1_rhNk2w {\n  max-width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/Section/style.module.less","webpack://src/assets/styles/icons.less"],"names":[],"mappings":"AAAA;;;;GAIG;ACJH;EACI,WAAA;EACA,YAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;ADMJ;ACJI;EACI,yDAAA;ADMR;AAZA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AAcF;AApBA;EASM,aAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;AAcN;AAZM;EACI,eAAA;AAcV","sourcesContent":["@import url('../../assets/styles/autoload.less');\n\n.section {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 60px;\n\n  .container {\n      display: flex;\n      flex-direction: column;\n      flex-wrap: wrap;\n      max-width: 1152px;\n      width: 100%;\n      padding: 20px 10px;\n\n      &--full {\n          max-width: 100%;\n      }\n  }\n}",".icon {\n    width: 22px;\n    height: 22px;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n\n    &__video {\n        background-image: url('../img/camera.svg');\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_2Cw6I-zdwSelXNzEqTqtpr",
	"icon__video": "_2lyDID4IyDvmQhAAKcD7EY",
	"section": "_3VfEhnoobj7mvllaW0psg-",
	"container": "_3PfxwkCvvXBVfuh1VdIKqe",
	"container--full": "_2l4k-6sWn1JJ0L1_rhNk2w"
};
export default ___CSS_LOADER_EXPORT___;
