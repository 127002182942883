import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import GradientText from '../GradientText';
import Section from '../Section';
import { selectModalContent, selectModalIsVisible, setModalIsVisible } from './modal.slice';
import style from './style.module.less';

export const Modal = (props) => {

    const content = useSelector(selectModalContent);
    const isVisible = useSelector(selectModalIsVisible);
    const dispatch = useDispatch();

    const handleBackgroundClick = (e) => {
        dispatch(setModalIsVisible(false));
        return;
    }

    // Disabled modal
    if( isVisible === false ) {
        return (<></>);
    }

    // Render
    return (
        <>
            <div className={style.modalBg} onClick={handleBackgroundClick}></div>
            <div className={style.modalWrapp}>
                <div className={style.modal}>
                    <Section className={style.modalSection}>
                        
                        {content}

                        <hr className={["hr-md", style.hr].join(' ')} />

                        <GradientText className={style.gradientText}>Powered by Reagent</GradientText>

                    </Section>
                </div>
            </div>
        </>
    );
}

export default Modal;