// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/camera.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n *\n * Animations\n *\n **/\n._3jYrN0dfa__r1g0Ja8XIEc {\n  width: 22px;\n  height: 22px;\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n._1t88w145BzOs1gzs_K7Ylv {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n._14a9j0Q566O0DxP8m_65jr {\n  width: 100%;\n  margin-bottom: 60px;\n}\n@media (max-width: 475px) {\n  ._14a9j0Q566O0DxP8m_65jr {\n    margin-bottom: 0px;\n  }\n}\n._14a9j0Q566O0DxP8m_65jr h2 {\n  width: 100%;\n  font-size: 42px;\n  line-height: 52px;\n  font-weight: 800;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/Title/style.module.less","webpack://src/assets/styles/icons.less"],"names":[],"mappings":"AAAA;;;;GAIG;ACJH;EACI,WAAA;EACA,YAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;ADMJ;ACJI;EACI,yDAAA;ADMR;AAZA;EACI,WAAA;EACA,mBAAA;AAcJ;AAZI;EAAA;IACI,kBAAA;EAeN;AACF;AArBA;EASQ,WAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAeR","sourcesContent":["@import url('../../assets/styles/autoload.less');\n\n.title {\n    width: 100%;\n    margin-bottom: 60px;\n\n    @media (max-width: @mobile-width) {\n        margin-bottom: 0px;\n    }\n\n    h2 {\n        width: 100%;\n        font-size: 42px;\n        line-height: 52px;\n        font-weight: 800;\n        text-align: center;\n    }\n}",".icon {\n    width: 22px;\n    height: 22px;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n\n    &__video {\n        background-image: url('../img/camera.svg');\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_3jYrN0dfa__r1g0Ja8XIEc",
	"icon__video": "_1t88w145BzOs1gzs_K7Ylv",
	"title": "_14a9j0Q566O0DxP8m_65jr"
};
export default ___CSS_LOADER_EXPORT___;
