import style from './style.module.less';

import FormButton from '../../Form/Button';
import FormText from '../../Form/Text';
import { useLayoutEffect, useRef, useState } from 'react';
import axios from 'axios';
import AgentItem from '../../Agents/AgentItem';
import { setGlobalField } from '../../../helpers';

const StepResult = (props) => {

  const {
    onSubmit = (e) => {},
    onChange = (e) => {}
  } = props;
  
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.reagent.pro'
  const url = `${baseUrl}/api/find_reagent/agents/search`;

  const [phone, setPhone] = useState('+7');
  const [agents, setAgents] = useState([]);

  const handleClickButton = (e) => {
    if( phone.length <= 6 ) {
      return alert('Введите пожалуйста правильный номер телефона!');
    }

    if( typeof onSubmit === 'function' ) {
      return onSubmit(e);
    }
  }

  const handleChangeInput = (e) => {
    setPhone(e.target.value);
    setGlobalField('searchTask_phone', e.target.value);

    if( typeof onChange === 'function' ) {
      return onChange(e);
    }
  }

	const gettingAgents = () => {

		let params =  {
			page: 1,
			per_page: 3
		};

    return axios.get(url, {
      params: params
    })
    .then((response) => {
      if( response.status === 200 ) {
        const agents = response.data.users;
        setAgents(agents);
      }
    });
	}

  const firstComponentUpdate = useRef(false);

  // Component Did Mount =)
  useLayoutEffect(() => {
    if( firstComponentUpdate.current === false ) {
      firstComponentUpdate.current = true;

      gettingAgents();
    }
  });

  // Render
  return (
    <>
      <h2 className={style.title}>Подходящие агенты для вашей задачи</h2>

      <div className={style.row}>
        <div className={[style.col, style.list].join(' ')}>

          <div className={style.title}>
            Выберите аттестованного агента для сотрудничества
          </div>

          <div className={style.agents}>
            {agents.map(agent => (
              <AgentItem
                key = {agent.id}
                avatar = {agent.avatar}
                first_name = {agent.first_name}
                last_name = {agent.last_name}
                phone = {agent.phone}
                reviews_count = {agent.reviews_count}
                review_rating = {agent.review_rating}
                www_link = {agent.www_link}
                className = {style.agent}
                isSelectable={true}
                agent={agent}
              />
            ))}
          </div>

        </div>
        <div className={[style.col, style.formCol].join(' ')}>
          
          <div className={style.title}>
            Или введите свой номер и система сама вас свяжет со одним специлалистом
          </div>
          
          <div className={style.form}>
            <div className={style.input__wrap}>
              <FormText className={style.input} value={phone} onChange={handleChangeInput} />
            </div>

            <FormButton onClick={handleClickButton} className={style.btn__form} isOuter={true}>Готово</FormButton>
          </div>

        </div>
      </div>
    </>
  );
}

export default StepResult;