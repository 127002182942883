import style from './style.module.less';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { findCenterFromEntities, numberFormatter } from '../../../helpers';

const EntitiesMap = (props) => {

    const {
        entities = []
    } = props;

    const entitesMapCenter = findCenterFromEntities(entities);

    let defaultState = {
        center: [entitesMapCenter.lat, entitesMapCenter.lng],
        zoom: 9
    };

    // Render
    return (
        <div className={style.container}>
            <YMaps>
                <Map className={style.map} defaultState={defaultState}>
                    {entities && entities.map(entity => {

                        let title = `${entity.street}`;

                        if( entity.home_number ) {
                            title += `, ${entity.home_number}`;
                        }

                        return (
                            <Placemark 
                                properties={{
                                    iconCaption: numberFormatter(entity.price) + ' ₽',
                                    balloonContent: 'a',
                                    hintContent: 'asdasd',
                                    balloonContentBody: 'dsaasd',
                                    balloonContentHeader: title
                                }}
                                geometry={[entity.latitude, entity.longitude]}
                            />
                        );
                    })}
                </Map>
            </YMaps>
        </div>
    );
}

export default EntitiesMap;