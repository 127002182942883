import { createSlice } from '@reduxjs/toolkit';

export const isAgentSelected = window.localStorage.getItem('selectedAgent') === 'Y';

export const AgentsSelectedSlice = createSlice({
    name: 'agentsSelected',
    initialState: {
        value: isAgentSelected,
        agent: isAgentSelected ? JSON.parse(window.localStorage.getItem('agent')) : {}
    },
    reducers: {
        setAgentsSelected: (state, action) => {
            state.value = action.payload;
        },
        setAgentsSelectedAgent: (state, action) => {
            state.agent = action.payload
        }
    }
});

export const { setAgentsSelected, setAgentsSelectedAgent } = AgentsSelectedSlice.actions;

export const selectAgentsSelected = state => state.agentsSelected.value;
export const selectAgentsSelectedAgent = state => state.agentsSelected.agent;

export const AgentsSelectedSliceReducer = AgentsSelectedSlice.reducer;

export default AgentsSelectedSliceReducer;