import Title from '../components/Title';
import SearchTask from '../components/SearchTask';
import Video from '../components/Video';
import Promo from '../components/Promo';
import PopularEntities from '../components/PopularEntities';
import Reviews from '../components/Reviews';

import Mock from '../mocks/home.mock';

/**
 * Home Page
 * URL: /
 * 
 * @param {object} props 
 * @returns 
 */
const Home = (props) => {

	const {
		promoItems,
		popularTitle,
		popularBtn,
		popularEntities,
		reviews,
		reviewsTitle,
		video,
		search,
		secondSearch
	} = Mock;

	return (
		<>
			<Video
				app={video.app}
				title={video.title}
				description={video.description}
				startBtn={video.startBtn}
				videoBtn={video.videoBtn}
				image={video.image}
			/>

			<SearchTask
				title={search.title}
				bottomText={search.bottomText}
				input={search.input}
				btn={search.btn}
				reducedSize={search.reducedSize}
			/>

			<Title>Как это работает</Title>

			{promoItems.map((item) => (
				<Promo data={item} key={item.title} />
			))}

			<PopularEntities
				title={popularTitle}
				btn={popularBtn}
				items={popularEntities}
			/>

			<Reviews reviews={reviews} title={reviewsTitle} />

			<SearchTask
				title={secondSearch.title}
				bottomText={secondSearch.bottomText}
				input={secondSearch.input}
				btn={secondSearch.btn}
				reducedSize={secondSearch.reducedSize}
			/>
		</>
	);
}

export default Home;