const Option = (props) => {
  
  const {
    children = '',
    value = ''
  } = props;

  // Render
  return (
    <option value={value}>{children}</option>
  )

}

export default Option;