import style from './style.module.less';

import Section from '../Section';
import FormSearch from '../Form/Search';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectEntitiesListSearch, setEntitesSearch } from '../Entities/entities.slice';

/**
 * Filter component
 *
 * @param {object} props
 * @returns
 */
const Filter = (props) => {

  const dispatch = useDispatch();
  const entitiesSearch = useSelector(selectEntitiesListSearch);

  const handleSearchChange = (e) => {
    dispatch(setEntitesSearch(e.target.value));
  }

  // Render
  return (
    <Section className={style.filter} containerClassName={style.container}>

      <h1>Ассоциация профессиональных риэлторов</h1>

      <h2>Объекты только от агентов по недвижимости</h2>

      <p>
        Сервис гарантирует отсутствие фейков в системе, так как здесь объекты из внутренней базы риэлторов. Это не классический класифайд, что бы посмотреть все объекты вам нужно выбрать себе агента проводника.
      </p>

      <FormSearch
        placeholder="Введите запрос, ЖК, улицу, район"
        name="search-field"
        className={style.field}
        wrapperClassName={style.wrapper}
        value={entitiesSearch}
        onChange={handleSearchChange}
      />

    </Section>
  );
}

export default Filter;
