import SearchTask from '../components/SearchTask';
import Video from '../components/Video';
import PopularEntities from '../components/PopularEntities';
import Reviews from '../components/Reviews';

import Mock from '../mocks/objects.mock';

/**
 * Objects Page
 * URL: /objects
 * 
 * @param {object} props 
 * @returns 
 */
const Objects = (props) => {

	// Mock promo blocks
	const {
		popularTitle,
		popularBtn,
		popularEntities,
		reviews,
		reviewsTitle,
		video,
		search,
	} = Mock;

	return (
		<>
			<Video 
				app={video.app} 
				title={video.title} 
				description={video.description}
				type={video.type}
				startBtn={video.startBtn}
				videoBtn={video.videoBtn}
				image={video.image} 
			/>

			<PopularEntities 
				title={popularTitle}
				btn={popularBtn}
				items={popularEntities} 
			/>

			<Reviews reviews={reviews} title={reviewsTitle} />

			<SearchTask
				title={search.title}
				bottomText={search.bottomText}
				input={ search.input }
				btn={ search.btn }
				reducedSize={search.reducedSize} 
			/>
		</>
	);
}

export default Objects;