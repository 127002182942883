import { configureStore } from '@reduxjs/toolkit';
import AgentsSelectedSliceReducer from '../components/AgentsSelected/agentsSelected.slice';
import EntitiesListSliceReducer from '../components/Entities/entities.slice';
import EntitiesSearchSliceReducer from '../components/Form/Search/entitiesSearch.slice';
import ModalSliceReducer from '../components/Modal/modal.slice';

export default configureStore({
    reducer: {
        entitiesList: EntitiesListSliceReducer,
        agentsSelected: AgentsSelectedSliceReducer,
        modal: ModalSliceReducer,
        entitiesSearch: EntitiesSearchSliceReducer
    }
});