// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/img/camera.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n *\n * Animations\n *\n **/\n._5ldVivYrsSP6GacMLk2fo {\n  width: 22px;\n  height: 22px;\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.aINDwFW7A5JLntJ-c8EMX {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n._2gtp_cydzVMyE71N345p45 {\n  width: 100%;\n  max-width: 650px;\n  margin-bottom: 60px;\n  text-align: center;\n  font-weight: 700;\n}\n._33VpIGDlZ7jO_cONgSN4kk {\n  margin-bottom: 25px;\n}\n", "",{"version":3,"sources":["webpack://src/components/SearchTask/StepEnd/style.module.less","webpack://src/assets/styles/icons.less"],"names":[],"mappings":"AAAA;;;;GAIG;ACJH;EACI,WAAA;EACA,YAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;ADMJ;ACJI;EACI,yDAAA;ADMR;AAZA;EACI,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAcJ;AAXA;EACI,mBAAA;AAaJ","sourcesContent":["@import url('../../../assets/styles/autoload.less');\n\n.title {\n    width: 100%;\n    max-width: 650px;\n    margin-bottom: 60px;\n    text-align: center;\n    font-weight: 700;\n}\n\n.btn__form {\n    margin-bottom: 25px;\n}",".icon {\n    width: 22px;\n    height: 22px;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n\n    &__video {\n        background-image: url('../img/camera.svg');\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_5ldVivYrsSP6GacMLk2fo",
	"icon__video": "aINDwFW7A5JLntJ-c8EMX",
	"title": "_2gtp_cydzVMyE71N345p45",
	"btn__form": "_33VpIGDlZ7jO_cONgSN4kk"
};
export default ___CSS_LOADER_EXPORT___;
