// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/img/camera.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n *\n * Animations\n *\n **/\n._1hWrgaqflMLW5abaT7ZuVv {\n  width: 22px;\n  height: 22px;\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n._3ecxkm4F5Q04L9_GeYPH2P {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n._1GxC7dnj0atzlleHqpzAwe {\n  width: 100%;\n  padding: 12px;\n  transition: border-color 0.3s ease;\n  border: 1px solid #E5E5E5;\n  border-radius: 5px;\n  background-color: #FAFAFA;\n}\n._1GxC7dnj0atzlleHqpzAwe:focus {\n  transition: border-color 0.3s ease;\n  border-color: #136FFB;\n}\n", "",{"version":3,"sources":["webpack://src/components/Form/Text/style.module.less","webpack://src/assets/styles/icons.less"],"names":[],"mappings":"AAAA;;;;GAIG;ACJH;EACI,WAAA;EACA,YAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;ADMJ;ACJI;EACI,yDAAA;ADMR;AAZA;EACE,WAAA;EACA,aAAA;EACA,kCAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;AAcF;AAZE;EACI,kCAAA;EACA,qBAAA;AAcN","sourcesContent":["@import url('../../../assets/styles/autoload.less');\n\n.field {\n  width: 100%;\n  padding: 12px;\n  transition: border-color .3s ease;\n  border: 1px solid @color-light-hard;\n  border-radius: 5px;\n  background-color: @color-light;\n\n  &:focus {\n      transition: border-color .3s ease;\n      border-color: @color-blue;\n  }\n}",".icon {\n    width: 22px;\n    height: 22px;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n\n    &__video {\n        background-image: url('../img/camera.svg');\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_1hWrgaqflMLW5abaT7ZuVv",
	"icon__video": "_3ecxkm4F5Q04L9_GeYPH2P",
	"field": "_1GxC7dnj0atzlleHqpzAwe"
};
export default ___CSS_LOADER_EXPORT___;
