import Section from '../Section';
import style from './style.module.less';
import NoneAvatar from '../../assets/img/none.svg';
import RatingStar from '../../assets/img/star.svg';
import CloseImage from '../../assets/img/close.svg';
import VerifiedIco from '../../assets/img/verified.svg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectAgentsSelected, selectAgentsSelectedAgent, setAgentsSelected, setAgentsSelectedAgent } from './agentsSelected.slice';
import declOfNum from '../../helpers';

export const AgentsSelected = (props) => {

    const dispatch = useDispatch();
    const agentsSelected = useSelector(selectAgentsSelected);
    const selectedAgent = useSelector(selectAgentsSelectedAgent);

    console.log('Agents selected', agentsSelected, selectedAgent);

    // Create classlist
    let classList = [style.section];

    if( agentsSelected === true ) {
        classList.push(style.visible);
    }

    // Getting avatar
    let avatar = NoneAvatar;

    if( selectedAgent?.avatar?.small ) {
        avatar = selectedAgent?.avatar?.small;
    }

    // Getting agent type
    let agentType = 'Агент';

    switch(selectedAgent.user_type) {
        case 'expert': agentType = 'Эксперт'; break;
        case 'broker': agentType = 'Брокер'; break;
        default: agentType = 'Агент'; break;
    }

    const handleCloseClick = (e) => {

        dispatch(setAgentsSelected(false));
        dispatch(setAgentsSelectedAgent({}));

        window.localStorage.setItem('agent', '{}');
        window.localStorage.setItem('selectedAgent', 'N');

        return true;
    }

    // Render
    return (
        <Section className={classList.join(' ')}>
            <div className={style.row}>

                <div className={style.headline}>
                    <h2>Ваш агент</h2>
                </div>

                <div className={style.agent}>
                    <div className={style.avatar}>
                        <img src={avatar} className={style.avatar_image} alt="" />
                        {selectedAgent.verified && (<img src={VerifiedIco} className={style.verified} alt="Верифицированный агент" />)}
                    </div>
                    <div className={style.properties}>
                        <div className={style.properties_line}>

                            <div className={style.rating}>
                                <img src={RatingStar} alt="" />
                                <strong>{selectedAgent.review_rating}.0</strong>
                                <a href={selectedAgent.www_link} target="_blank" rel="noreferrer">{selectedAgent?.reviews_count} {declOfNum(selectedAgent?.reviews_count, ['отзыв','отзыва','отзывов'])}</a>
                            </div>

                            <div className={style.agent_type}>
                                {agentType} {selectedAgent.agency_name && (<>/ {selectedAgent.agency_name}</>)}
                            </div>

                        </div>

                        <div className={style.agent_name}>
                            <a href={selectedAgent.www_link} target="_blank" rel="noreferrer">
                                {selectedAgent.last_name} {selectedAgent.first_name} {selectedAgent.middle_name}
                            </a>
                        </div>

                        <div className={style.text}>
                            <p>
                                {selectedAgent?.description?.substring(0, 90)}
                                {selectedAgent?.description?.length > 90 && (<>...</>)}
                            </p>
                        </div>

                    </div>
                </div>

                <div className={style.buttons}>
                    <a href={selectedAgent.www_link} target="_blank" rel="noreferrer" className={style.btn_connect}>Перейти в профиль</a>
                    <div className={style.btn_close} onClick={handleCloseClick}>
                        <img src={CloseImage} alt="Close popup" />
                    </div>
                </div>

            </div>
        </Section>
    );
}

export default AgentsSelected;
