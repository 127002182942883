import { createSlice } from '@reduxjs/toolkit';

export const EntitiesListSlice = createSlice({
    name: 'entitiesList',
    initialState: {
        value: [],
        search: ''
    },
    reducers: {
        setEntitesSearch: (state, action) => {
            state.search = action.payload;
        }
    }
});

export const { setEntitesSearch } = EntitiesListSlice.actions;

export const selectEntitiesList = state => state.entitiesList.value;
export const selectEntitiesListSearch = state => state.entitiesList.search;

export const EntitiesListSliceReducer = EntitiesListSlice.reducer;

export default EntitiesListSliceReducer;