import style from './style.module.less';
import icon from '../../../assets/img/handwith.svg';

/**
 * Entity Message component
 * 
 * @param {object} props 
 * @returns 
 */
const EntityMessage = (props) => {

    const {
        className = ''
    } = props;

    // Render
    return (
        <div className={[style.container, className].join(' ')}>
            <img src={icon} className={style.icon} />
            <div className={style.row}>
                <span>Получите помощь в поиске подходящего объекта.</span>
                <span className={style.bolder}>Свяжитесь с локальным агентом.</span>
            </div>
        </div>
    )
}

export default EntityMessage;