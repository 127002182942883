import style from './style.module.less';

import Section from '../Section';

/**
 * Promotion Component
 *
 * @param {object} props
 * @returns
 */
const TextBlock = (props) => {

    const {
        title = 'Real Estate database',
        subTitle = 'Профессионалы рынка недвижимости',
        description = 'Сервис, который объединяет профессионалов рынка недвижимости. Точкой входа является Цифровой профиль Риэлтора и сертификация специалиста. Агенты закреплены за локациями, рынок которых знают лучше всего.',
        className = ''
    } = props;

    let classList = [style.section, className]

    // Render
    return (
        <Section className={classList.join(' ')} containerClassName={style.container}>

            <div className={style.rows}>
                <div className={style.title}>
                    {title}
                </div>
                <div className={style.sub_title}>
                    <h1>{subTitle}</h1>
                </div>
                <div className={style.description}>
                    <p>{description}</p>
                </div>
            </div>

        </Section>
    );
}

export default TextBlock;
