import style from './style.module.less';

import Section from '../Section';

/**
 * Promotion Component
 *
 * @param {object} props
 * @returns
 */
const Promo = (props) => {

    const {
        data
    } = props;

    if( typeof data !== 'object' ) {
        return (<></>);
    }

    const {
        image = '',
        title = 'Выберите какую задачу вы хотите решить',
        description = 'Выбрав задачу которую вам надо выполнить, системе проще подобрать вам исполнителя. Так как учитывается сценарий задачи, локация объекта, опыт и рейтинг агента исполнителя.',
        button = {},
        reverse = false
    } = data;

    let classList = [style.promo];

    if( reverse ) {
        classList.push(style.reverse);
    }

    // Render
    return (
        <Section id="go" className={classList.join(' ')} containerClassName={style.container}>

            <div className={style.promo_row}>
                <div className={[style.promo_col, style.promo_col__image].join(' ')}>

                    <img src={image} alt={title} />

                </div>
                <div className={[style.promo_col, style.promo_col__text].join(' ')}>

                    <h2>{title}</h2>
                    <p>{description}</p>

                    {button?.link && <a href={button.link}>{button.text}</a>}

                </div>
            </div>

        </Section>
    );
}

export default Promo;
