import { useState, useEffect } from 'react';
import axios from 'axios';

import AgentsFilter from '../components/AgentsFilter';
import AgentsPopular from '../components/Agents';

import Mock from '../mocks/agents.mock';
import AgentsList from '../components/AgentsList';
import Pagination from '../components/Pagination';
import TextBlock from '../components/TextBlock';

/**
 * Agents component
 */
const Agents = () => {

  const {
    menuItems
  } = Mock;

  const [popularAgentsData, setPopularAgentsData] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [countSpecialists, setCountSpecialists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({});
  const perPage = 14;
  const url = `${process.env.REACT_APP_API_URL}/api/find_reagent/agents/search`;

  /**
   * On filter changed
   *
   * @param {object} data
   */
  const handleFilterChange = (data) => {
    setFilterData(data);
  }

  /**
   * For update data from server by filterData
   *
   * @param {object} filterData
   */
  const updateData = (filterData) => {

    let params = {
      page: currentPage,
      per_page: perPage
    };

    if (typeof filterData.location === 'string' && filterData.location.length >= 4) {
      params.location = filterData.location;
    }

    if (typeof filterData.name === 'string' && filterData.name.length >= 2) {
      params.name = filterData.name;
    }

    if (typeof filterData.service === 'string' && filterData.service.length >= 1) {
      params.service = filterData.service;
    }

    // Remove last timeout for sending request
    clearTimeout(window.requestTimeout);

    // For sending single request
    window.requestTimeout = setTimeout(() => {
      axios.get(url, {
        params: params
      })
        .then((response) => {
          if (response.status === 200) {
            let agents = response.data.users;
            const popularAgents = agents.splice(0, 6);

            setAgentsData(agents);
            setCountSpecialists(response.data.meta.total);
            setPopularAgentsData(popularAgents);
          }
        });
    }, 1000);
  }

  /**
   * On page change
   *
   * @param {number} page
   * @param {DOMElement} item
   * @param {Event} e
   */
  const handleChangePage = (page, item, e) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    updateData(filterData);
  }, [filterData, currentPage]);

  // Render
  return (
    <>
      <TextBlock
        title="Ассоциация профессиональных риэлторов"
        subTitle="Профессионалы рынка недвижимости"
        description="Сервис, который объединяет профессионалов рынка недвижимости. Точкой входа является Цифровой профиль Риэлтора и сертификация специалиста. Агенты закреплены за локациями, рынок которых знают лучше всего."
      />

      <AgentsFilter
        onChangeFilter={handleFilterChange}
        menu={menuItems}
      />

      <AgentsPopular
        isBtnFull={false}
        isBorderBottom={false}
        agents={popularAgentsData}
        countSpecialists={countSpecialists}
        currentPage={currentPage}
      />

      <AgentsList
        agents={agentsData}
      />

      <Pagination
        items={countSpecialists}
        perPage={perPage}
        onChangePage={handleChangePage}
      />
    </>
  );
}

export default Agents;
