import style from './style.module.less';

import Section from '../Section';
import Entity from './Entity';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEntitiesListSearch } from './entities.slice';
import declOfNum from '../../helpers';
import { useDispatch } from 'react-redux';
import { setModalContent, setModalIsVisible } from '../Modal/modal.slice';
import EntitiesModal from './EntitiesModal';
import { selectEntitiesMapShow } from '../Form/Search/entitiesSearch.slice';
import EntityMessage from './EntityMessage';
import EntitiesMap from './EntitiesMap';

export const getEntities = (search) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.reagent.pro'
  let url = `${baseUrl}/api/find_reagent/ads/search`;

  if (search && search?.length >= 2) {
    url += `?q=${search}&per_page=9`;
  } else {
    // url += `?status=active`;
  }

  return axios.get(url).then(response => {
    return response.data;
  });
};

/**
 * List objects component
 *
 * @param {object} props
 * @returns
 */
const Entities = (props) => {

  const [total, setTotal] = useState(0);
  const requestTimer = useRef(0);
  const entitiesSearch = useSelector(selectEntitiesListSearch);
  const isVisibleMap = useSelector(selectEntitiesMapShow);
  const dispatch = useDispatch();
  const [objects, setObjects] = useState([]);

  let containerClassList = [style.entitiesContainer];
  let mapContainer = (<></>);

  // Research data
  useEffect(() => {

    // Fix: too many requests
    clearTimeout(requestTimer.current);
    requestTimer.current = setTimeout(() => {
      getEntities(entitiesSearch)?.then(data => {
        setObjects(data?.ads);
        setTotal(data?.meta?.total);
      });
    }, 500);

  }, [entitiesSearch]);

  // Modal
  const handleFullObjectsClick = (e) => {
    e.preventDefault();

    dispatch(setModalIsVisible(true));
    dispatch(setModalContent((
      <EntitiesModal />
    )));

    return;
  }

  // For tags
  const firstEntity = objects[0];
  let tags = [];

  if (firstEntity && firstEntity?.id >= 1) {

    if (firstEntity?.city && firstEntity?.city?.name) {
      tags.push(firstEntity?.city?.name);
    }

    if (firstEntity?.street) {
      tags.push(firstEntity?.street);
    }

    if (firstEntity?.area >= 1) {
      tags.push(`${firstEntity?.area} кв. м.`);
    }

    if (firstEntity?.rooms && firstEntity?.rooms !== 'free') {
      tags.push(`${firstEntity?.rooms}K`);
    }

  }

  // Getting range objects
  const sliceObjects = isVisibleMap ? objects.slice(0, 4) : objects.slice(0, 9);

  // If we need a see map with entities
  if (isVisibleMap) {

    // Add class to container
    containerClassList.push(style.isVisibleMap);

    mapContainer = (<></>);
  }

  // Render
  return (
    <Section className={style.entities}>

      <div className={style.header}>
        <h2>Объекты из закрытой профессиональной базы</h2>
        <h2 className={style.counter}>{total} {declOfNum(total, ['объект', 'объекта', 'объектов'])}</h2>
      </div>

      <div className={style.tags}>
        {entitiesSearch?.length >= 1 ? (
          <span className={style.tag}>{entitiesSearch}</span>
        ) : (
          tags.map(tag => (<span className={style.tag} key={tag}>{tag}</span>))
        )}
        {}
      </div>

      <div className={containerClassList.join(' ')}>


        {isVisibleMap && (
          <div className={style.map}>
            <EntitiesMap entities={objects} />
          </div>
        )}

        <div className={style.row}>

          {isVisibleMap && (
            <EntityMessage />
          )}

          {
            sliceObjects.length <= 0 ? (
              <h2>Объекты не найдены</h2>
            ) : sliceObjects.map(entity => {
              return (<Entity className={style.item} key={entity.id} data={entity} />);
            })
          }

          {isVisibleMap && (
            <div className={style.footerWithMap}>
              <Link to="/" className={style.footerBtn} onClick={handleFullObjectsClick}>
                Посмотреть все объекты ({total} {declOfNum(total, ['объект', 'объекта', 'объектов'])})
              </Link>
            </div>
          )}

        </div>
      </div>

      {!isVisibleMap && (
        <div className={style.footer}>
          <Link to="/" onClick={handleFullObjectsClick}>Посмотреть все объекты</Link>
        </div>
      )}

    </Section>
  );
}

export default Entities;
