// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/camera.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n *\n * Animations\n *\n **/\n.thnYeSC66NLzXwkInd0NT {\n  width: 22px;\n  height: 22px;\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.OTppwyHa0Vq-h_S8Pkgb6 {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n._3tlneD_1SYm3jPSRMszt0W {\n  display: flex;\n  flex-wrap: nowrap;\n  width: 100%;\n}\n._3tlneD_1SYm3jPSRMszt0W ._27gP9s32Yv95hy82T-kOY {\n  display: none;\n  width: 100%;\n}\n._3tlneD_1SYm3jPSRMszt0W ._1mrHp-2Gy18HQKnA55D5GU {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/Tabs/style.module.less","webpack://src/assets/styles/icons.less"],"names":[],"mappings":"AAAA;;;;GAIG;ACJH;EACI,WAAA;EACA,YAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;ADMJ;ACJI;EACI,yDAAA;ADMR;AAZA;EACE,aAAA;EACA,iBAAA;EACA,WAAA;AAcF;AAjBA;EAMI,aAAA;EACA,WAAA;AAcJ;AArBA;EAWI,aAAA;EACA,sBAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AAaJ","sourcesContent":["@import url('../../assets/styles/autoload.less');\n\n.tabs {\n  display: flex;\n  flex-wrap: nowrap;\n  width: 100%;\n\n  .tab {\n    display: none;\n    width: 100%;\n  }\n\n  .active {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n  }\n}",".icon {\n    width: 22px;\n    height: 22px;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n\n    &__video {\n        background-image: url('../img/camera.svg');\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "thnYeSC66NLzXwkInd0NT",
	"icon__video": "OTppwyHa0Vq-h_S8Pkgb6",
	"tabs": "_3tlneD_1SYm3jPSRMszt0W",
	"tab": "_27gP9s32Yv95hy82T-kOY",
	"active": "_1mrHp-2Gy18HQKnA55D5GU"
};
export default ___CSS_LOADER_EXPORT___;
