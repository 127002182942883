import style from './style.module.less';

import {NavLink, Link} from 'react-router-dom';
import Section from '../Section';

import logo from '../../assets/img/logo.svg';
import { useSelector } from 'react-redux';
import { selectAgentsSelected } from '../AgentsSelected/agentsSelected.slice';

/**
 * Header Component
 * 
 * @returns 
 */
const Header = () => {

    const selectedAgents = useSelector(selectAgentsSelected);
    let classList = [style.header];

    if( selectedAgents === true ) {
        classList.push(style.marginTop);
    }

    // Render
    return (
        <Section className={classList.join(' ')}>

            <div className={style.row}>
                <div className={style.logo}>
                    <Link to="/">
                        <img src={logo} alt="Get Agent" />
                    </Link>
                </div>
                <div className={style.menu}>
                    <ul>
                        <li>
                            <NavLink activeClassName={style.active} to="/agents">Агенты</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName={style.active} to="/objects">Объекты</NavLink>
                        </li>
                    </ul>
                </div>
            </div>

        </Section>
    )
}  

export default Header;