import { useState } from 'react';
import style from './style.module.less';

/**
 * Select for form
 * 
 * @param {object} props 
 * @returns 
 */
const FormSelect = (props) => {

  const {
    name:selectName = '',
    children = '',
    className = '',
    wrapperClassName = '',
    arrowClassName = '',
    onChange = (e) => {},
    onFocus = (e) => {},
    onBlur = (e) => {},
    value = ''
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const [selectValue, setSelectValue] = useState(value);

  let additionalClasses = [];

  const handleFocusSelect = (e) => {
    setIsFocus(true);
    onFocus(e);
  }

  const handleBlurSelect = (e) => {
    setIsFocus(false);
    onBlur(e);
  }

  const handleChangeSelect = (e) => {
    setSelectValue(e.target.value);
    if( typeof onChange === 'function' ) {
      onChange(e);
    }
  }

  if( isFocus ) {
    additionalClasses.push(style.focus);
  }

  // Render
  return (
    <div className={[style.wrapper, additionalClasses, wrapperClassName].join(' ')}>
      
      <select 
        onBlur={handleBlurSelect}
        onFocus={handleFocusSelect}
        onChange={handleChangeSelect}
        className={[style.field, className].join(' ')}
        name={selectName}
        value={selectValue}
      >
        {children}
      </select>

      <div className={[style.arrow, arrowClassName].join(' ')}></div>
    </div>
  );
}

export default FormSelect;