import { createSlice } from '@reduxjs/toolkit';

export const ModalSlice = createSlice({
    name: 'modal',
    initialState: {
        content: (<></>),
        isVisible: false
    },
    reducers: {
        setModalContent: (state, action) => {
            state.content = action.payload;
        },
        setModalIsVisible: (state, action) => {
            state.isVisible = action.payload;
        }
    }
});

export const { setModalContent, setModalIsVisible } = ModalSlice.actions;

export const selectModalContent = state => state.modal.content;
export const selectModalIsVisible = state => state.modal.isVisible;

export const ModalSliceReducer = ModalSlice.reducer;

export default ModalSliceReducer;