// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/camera.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n *\n * Animations\n *\n **/\n.TjcrdxGaiTkD-_8eUKfAk {\n  width: 22px;\n  height: 22px;\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.AM6N69OCazPgmvGsPBmZm {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.SNF3RYs8qyZUuyVCKU4Uw {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 17px;\n  background: linear-gradient(90deg, #00C2FE 0%, #006EF9 100%);\n  background-clip: text;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n", "",{"version":3,"sources":["webpack://src/components/GradientText/style.module.less","webpack://src/assets/styles/icons.less"],"names":[],"mappings":"AAAA;;;;GAIG;ACJH;EACI,WAAA;EACA,YAAA;EACA,2BAAA;EACA,wBAAA;EACA,4BAAA;ADMJ;ACJI;EACI,yDAAA;ADMR;AAZA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,4DAAA;EACA,qBAAA;EACA,6BAAA;EACA,oCAAA;AAcF","sourcesContent":["@import url('../../assets/styles/autoload.less');\n\n.coopyrights {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 17px;\n  background: @gradient-primary;\n  background-clip: text;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}",".icon {\n    width: 22px;\n    height: 22px;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n\n    &__video {\n        background-image: url('../img/camera.svg');\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "TjcrdxGaiTkD-_8eUKfAk",
	"icon__video": "AM6N69OCazPgmvGsPBmZm",
	"coopyrights": "SNF3RYs8qyZUuyVCKU4Uw"
};
export default ___CSS_LOADER_EXPORT___;
