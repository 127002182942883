import { useState } from 'react';
import style from './style.module.less';

import FormButton from '../../Form/Button';
import { setGlobalField } from '../../../helpers';

function StepType(props) {

  const {
    items = [
      {value: '1', title: 'Квартира'},
      {value: '2', title: 'Дом'},
      {value: '3', title: 'Участок'},
      {value: '4', title: 'Коммерция'},
    ],
    onSubmit = (e) => {},
    onChange = (e) => {}
  } = props;

  const [selectedType, setSelectedType] = useState(items[0]?.value);

  function handleClickButton(e) {

    setGlobalField('searchTask_type', selectedType);

    if( typeof onSubmit === 'function' ) {
      return onSubmit(e);
    }
  }

  function handleChangeType(e) {

    setSelectedType(e.target.dataset.value);
    setGlobalField('searchTask_type', e.target.dataset.value);

    if( typeof onChange === 'function' ) {
      return onChange(e);
    }
  }

  return (
    <>
      <h2>Выберите тип недвижимости</h2>

      <div className={style.form}>
        
        <div className={style.items}>
          {items.map(item => {

            let classList = [style.item];

            if( selectedType === item.value ) {
              classList.push(style.item__active);
            }

            return (
              <div className={classList.join(' ')} data-value={item.value} onClick={handleChangeType} key={item.title}>
                {item.title}
              </div>
            );
          })}
        </div>

        <FormButton onClick={handleClickButton} className={style.btn__form} isOuter={true}>Готово</FormButton>
      </div>
    </>
  );
}

export default StepType;