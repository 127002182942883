import style from './style.module.less';

/**
 * Gradient Text Component
 * 
 * @param {object} props 
 * @returns 
 */
const GradientText = (props) => {
  const {
    className = '',
    children = 'Example Text'
  } = props;

  // Render
  return (
    <span className={[style.coopyrights, className].join(' ')}>
      {children}
    </span>
  );
}

export default GradientText;