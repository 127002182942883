import style from './style.module.less';

/**
 * Section Component
 *
 * @param {object} props
 * @returns
 */
const Section = (props) => {

  const {
    id,
    className,
    containerClassName,
    children
  } = props;

  // Render
  return (
    <section id={id} className={[style.section, className].join(' ')}>
      <div className={[style.container, containerClassName].join(' ')}>

        {children}

      </div>
    </section>
  );
}

export default Section;
