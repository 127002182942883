import PromoImage from '../assets/img/image.png';

import VideoImage from '../assets/img/objectsImage.png';

import PEImage1 from '../assets/img/entity-popular (1).png';
import PEImage2 from '../assets/img/entity-popular (2).png';
import PEImage3 from '../assets/img/entity-popular (3).png';
import PEImage4 from '../assets/img/entity-popular (4).png';
import PEImage5 from '../assets/img/entity-popular (5).png';
import PEImage6 from '../assets/img/entity-popular (6).png';
import PEImage7 from '../assets/img/entity-popular (7).png';

import ReviewImage1 from '../assets/img/Ellipse 1.png';
import ReviewImage2 from '../assets/img/Ellipse 2.png';
import ReviewImage3 from '../assets/img/Ellipse 3.png';

const MockData = {

    // Video block
    video: {
        app: '',
        title: 'Объекты только от агентов по недвижимости',
        description: 'Сервис гарантирует отсутствие фейков в системе, так как здесь объекты из внутренней базы риэлторов. Это не классический класифайд, что бы посмотреть все объекты вам нужно выбрать себе агента проводника.',
        type: 2,
        startBtn: {
            text: 'Начать',
            link: '#go'
        },
        videoBtn: {
            text: 'Посмотреть видео',
            link: '#go'
        },
        image: VideoImage
    },

    // Search Tasks block
    search: {
        title: 'Выберите какую задачу вы хотите решить',
        bottomText: 'Powered by Reagent',
        reducedSize: false,
        input: {
            placeholder: 'Начните вводить название...',
        },
        btn: {
            text: 'Отправить оффер'
        }
    },

    // Promo Block
    promoItems: [
        {
            id: 100,
            image: PromoImage,
            title: 'Выберите какую задачу вы хотите решить',
            description: 'Выбрав задачу которую вам надо выполнить, системе проще подобрать вам исполнителя. Так как учитывается сценарий задачи, локация объекта, опыт и рейтинг агента исполнителя.',
            button: {
                link: '#go',
                text: 'Начать'
            }
        },

        {
            id: 102,
            image: PromoImage,
            title: 'Начните выбор объектов',
            description: 'Агент предоставит вам доступ в закрытую базу по недвижимости, где есть все реальные предложения без фейков от все агентов страны но под профилем вашего агента.',
            reverse: true
        },

        {
            id: 103,
            image: PromoImage,
            title: 'Запланируйте просмотр',
            description: 'Получите подборку объектов и запланируйте их просмотр онлайн или оффлайн по видео и 3D туру.'
        }
    ],

    // Popular Entities block
    popularTitle: 'Популярные объекты из закрытой профессиональной базы',
    popularBtn: {
        text: 'Просмотреть все объекты',
        link: '/'
    },
    popularEntities: [
        {
            label: 'Санкт-Петербург',
            image: PEImage2,
            link: '/'
        },
        {
            label: 'Воронеж',
            image: PEImage1,
            link: '/'
        },
        {
            label: 'Москва',
            image: PEImage4,
            link: '/'
        },

        {
            label: 'Сочи',
            image: PEImage5,
            link: '/'
        },
        {
            label: 'Москва',
            image: PEImage3,
            link: '/'
        },
        {
            label: 'Краснодар',
            image: PEImage6,
            link: '/'
        },

        {
            label: 'Анапа',
            image: PEImage7,
            link: '/'
        },
        {
            label: 'Тамбов',
            image: PEImage2,
            link: '/'
        },
        {
            label: 'Новороссийск',
            image: PEImage5,
            link: '/'
        },

        {
            label: 'Барнаул',
            image: PEImage1,
            link: '/'
        },
        {
            label: 'Новосибирск',
            image: PEImage5,
            link: '/'
        },
        {
            label: 'Красноярск',
            image: PEImage7,
            link: '/'
        },

        {
            label: 'Иркутск',
            image: PEImage6,
            link: '/'
        },
        {
            label: 'Владивосток',
            image: PEImage2,
            link: '/'
        },
        {
            label: 'Магадан',
            image: PEImage1,
            link: '/'
        },
    ],

    // Reviews Block
    reviewsTitle: 'Что говорят пользователи',
    reviews: [
        {
            image: ReviewImage1,
            description: 'Выбирать из реальных объектов удобней, чем тратить время на популярные агрегаторы.'
        },
        {
            image: ReviewImage2,
            description: 'Выбирать из реальных объектов удобней, чем тратить время на популярные агрегаторы.'
        },
        {
            image: ReviewImage3,
            description: 'Выбирать из реальных объектов удобней, чем тратить время на популярные агрегаторы.'
        }
    ],

    // Search Tasks block
    secondSearch: {
        title: 'Выберите какую задачу вы хотите решить',
        bottomText: 'Powered by Reagent',
        reducedSize: true,
        input: {
            placeholder: 'Начните вводить название...',
        },
        btn: {
            text: 'Отправить оффер'
        }
    },
};

export default MockData;
