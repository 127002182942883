import style from './style.module.less';

/**
 * Review Item for reviews
 * 
 * @param {object} props 
 * @returns 
 */
const ReviewItem = (props) => {

    const {
        data
    } = props;

    if( typeof data !== 'object' ) {
        return (<></>);
    }

    if( typeof data.image !== 'string' ) {
        return (<></>);
    }

    const {
        image,
        name = '',
        description
    } = data;

    // Render
    return (
        <div className={style.review}>
            <div className={style.avatar}>
                <img src={image} alt={description} />
            </div>
            <div className={style.name}>
                {name}
            </div>
            <div className={style.description}>
                {description}
            </div>
        </div>
    );
}

export default ReviewItem;