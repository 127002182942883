import style from './style.module.less';

/**
 * Button Component
 * 
 * @param {object} props 
 * @returns 
 */
const FormButton = (props) => {

  const {
    isDark = false,
    isFull = false,
    isOuter = false,
    className = '',
    onClick = (e) => {},
    children = (<></>)
  } = props;

  let classList = [style.btn, className];

  const handleClickButton = (e) => {
    if( typeof onClick === 'function' ) {
      return onClick(e);
    }
  }

  if( isDark === true ) {
    classList.push(style.dark);
  }

  if( isFull === true ) {
    classList.push(style.full);
  }

  if( isOuter === true ) {
    classList.push(style.outer);
  }

  return (
    <button 
      className={classList.join(' ')}
      onClick={handleClickButton}
    >
      {children}
    </button>
  );
}

export default FormButton;