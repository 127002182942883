import style from './style.module.less';

/**
 * React component Button with link
 * 
 * @param {object} props 
 * @returns 
 */
const LinkButton = (props) => {
  const {
    isDark = false,
    isFull = false,
    isOuter = false,
    className = '',
    children = (<></>),
    target = '',
    alt = '',
    title = '',
    to = '',
    rel = ''
  } = props;

  let linkRel = rel;

  if( target === '_blank' && linkRel.length <= 0 ) {
    linkRel = 'noreferrer';
  }

  let classList = [style.btn, className];

  if( isDark === true ) {
    classList.push(style.dark);
  }

  if( isFull === true ) {
    classList.push(style.full);
  }

  if( isOuter === true ) {
    classList.push(style.outer);
  }

  // Render
  return (
    <a 
      href = {to}
      className = {classList.join(' ')}
      target = {target}
      alt = {alt}
      title = {title}
      rel={linkRel}
    >
      {children}
    </a>
  );
}

export default LinkButton;