import style from './style.module.less';

import Section from '../Section';

/**
 * Title Component
 * 
 * @param {object} props 
 * @returns 
 */
const Title = (props) => {

    const {
        children = (<></>)
    } = props;

    // Render
    return (
        <Section className={style.title}>
            <h2>{children}</h2>
        </Section>
    );
}

export default Title;