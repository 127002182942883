import style from './style.module.less';

import Section from '../Section';
import {Link} from 'react-router-dom';

import ImageArrow from '../../assets/img/right-arrow.svg';
import AgentItem from './AgentItem';
import declOfNum from '../../helpers';

/**
 * Popular Agents component
 *
 * @param {object} props
 * @returns
 */
const Agents = (props) => {

  const {
    isBtnFull = true,
    isBorderBottom = true,
    className = '',
    agents = [],
    countSpecialists = 0
  } = props;

  let classList = [style.section, className];

  if( isBorderBottom === true ) {
    classList.push(style.border_bottom);
  }

  // Empty render if agents not found
	if( !agents || agents.length <= 0 ) {
		return (
			<></>
		);
	}

  // Render
  return (
    <Section className={classList.join(' ')}>
      <div className={style.wrapp}>

        <h2>Агенты</h2>
        <div className={style.header}>
          <h3 className={style.subtitle}>Выберите аттестованного агента для сотрудничества</h3>
          <h3 className={style.counter}>{countSpecialists} {declOfNum(countSpecialists, ['специалист','специалиста','специалистов']) }</h3>
        </div>

        <div className={style.row}>
          {agents.map(agent => (
            <AgentItem
              key={agent.id}
              avatar={agent.avatar}
              first_name={agent.first_name}
              last_name={agent.last_name}
              phone={agent.phone}
              reviews_count={agent.reviews_count}
              review_rating={agent.review_rating}
              www_link={agent.www_link}
              agent={agent}
              isSelectable={true}
            />
          ))}
        </div>

        {(
          isBtnFull &&
          <div className={style.arrow}>
            <Link to="/" className={style.arrow__link}>
              <img src={ImageArrow} alt="Next" />
            </Link>
          </div>
         )}

      </div>
    </Section>
  )
}

export default Agents;
