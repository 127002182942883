import style from './style.module.less';

import { useDispatch } from 'react-redux';
import { setModalIsVisible } from '../../Modal/modal.slice';
import { useSelector } from 'react-redux';
import { selectAgentsSelectedAgent } from '../../AgentsSelected/agentsSelected.slice';
import declOfNum from '../../../helpers';

export const EntitiesModal = (props) => {

    const dispatch = useDispatch();
    const selectedAgent = useSelector(selectAgentsSelectedAgent);

    const handleCloseModal = (e) => {
        dispatch(setModalIsVisible(false));
    }

    // If agent not selected
    if (!selectedAgent?.id) {
        return (
            <div className={style.modal}>
                <div className={[style.modalRow, style.modalText].join(' ')}>
                    <p>
                      Сервис гарантирует отсутствие фейков в системе, так как здесь объекты из внутренней базы риэлторов. Это не классический класифайд, что бы посмотреть все объекты вам нужно <a href="/agents">выбрать себе агента</a> проводника.
                    </p>
                </div>

                <div className={style.modalRow}>
                    <a onClick={handleCloseModal} className={[style.modalButton, style.modalButtonBlack].join(' ')}>
                        Ок
                    </a>
                </div>
            </div>
        );
    }

    // Render
    return (
        <div className={style.modal}>

            <div className={style.modalHead}>
                <h2>Ваш агент</h2>
            </div>

            <div className={style.modalAvatar}>
                <div className={style.modalAvatarWrapper} style={{ backgroundImage: `url('${selectedAgent.avatar.original}')` }}></div>
            </div>

            <div className={style.modalRating}>
                {selectedAgent?.review_rating && (
                    <div className={style.modalRatingValue}>
                        {selectedAgent?.review_rating}.0
                    </div>
                )}
                <div className={style.modalReviews}>
                    <a href={selectedAgent.www_link}>{selectedAgent?.reviews_count} {declOfNum(selectedAgent?.reviews_count, ['отзыв', 'отзыва', 'отзывов'])}</a>
                </div>
            </div>

            <div className={style.modalRow}>
                <div className={style.modalFullName}>
                    <a href="/" target="_blank" rel="referrer">
                        {selectedAgent?.last_name} {selectedAgent?.first_name} {selectedAgent?.middle_name}
                    </a>
                </div>
            </div>

            <div className={style.modalRow}>
                <div className={[style.modalCol, style.modalPhone].join(' ')}>
                    +{selectedAgent.phone}
                </div>
                <div className={[style.modalCol, style.modalEmail].join(' ')}>
                    {selectedAgent?.email}
                </div>
                <div className={[style.modalCol, style.modalWhatsapp].join(' ')}>
                    <a href={selectedAgent?.whatsapp} target="_blank" rel="referrer">WhatsApp</a>
                </div>
            </div>

            <div className={[style.modalRow, style.modalText].join(' ')}>
                <p>
                  Сервис гарантирует отсутствие фейков в системе, так как здесь объекты из внутренней базы риэлторов. Это не классический класифайд, что бы посмотреть все объекты вам нужно <a href={selectedAgent?.www_link} target="_blank" rel="referrer"> связаться с агентом</a>
                </p>
            </div>

            <div className={style.modalRow}>
                <a onClick={handleCloseModal} href="/agents" className={style.modalButton}>
                    Выбрать другого агента
                </a>
            </div>

        </div>
    );
}

export default EntitiesModal;
