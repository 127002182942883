import { useState } from 'react';
import style from './style.module.less';

import Section from '../Section';
import GradientText from '../GradientText';
import StepSelect from './StepSelect';
import StepCity from './StepCity';
import StepType from './StepType';
import StepResult from './StepResult';
import Tabs from '../Tabs';
import StepEnd from './StepEnd';

/**
 * Search Tasks component
 *
 * @param {object} props
 * @returns
 */
function SearchTask(props) {

	const {
		bottomText = 'Powered by Reagent',
		reducedSize = false,
	} = props;

	const [selectedTab, setSelectedTab] = useState(0);

	let classList = [style.search_task];
	let containerClassList = [style.container];

	if( reducedSize ) {
		classList.push(style.reduced);
		containerClassList.push(style.reduced_container);
	}

	function handleStepSubmit(e) {
		setSelectedTab(selectedTab + 1);
	}

	function handleReloadForm(e) {
		setSelectedTab(0);
	}

	// Render
	return (
		<Section id="go" className={classList.join(' f ')} containerClassName={containerClassList.join(' ')}>

			<Tabs selectedTab={selectedTab}>
				<StepSelect onSubmit={handleStepSubmit} />
				<StepCity onSubmit={handleStepSubmit} />
				<StepType onSubmit={handleStepSubmit} />
				<StepResult onSubmit={handleStepSubmit} />
				<StepEnd onSubmit={handleReloadForm} />
			</Tabs>

			<hr className={["hr-md", style.hr].join(' ')} />

			<GradientText>{bottomText}</GradientText>

		</Section>
	);
}

export default SearchTask;
