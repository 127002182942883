import PromoImage1 from '../assets/img/image_1.png';
import PromoImage2 from '../assets/img/image_2.png';
import PromoImage3 from '../assets/img/image_3.png';

import VideoImage from '../assets/img/homeimage.png';

import PEImage1 from '../assets/img/entity-popular (1).png';
import PEImage2 from '../assets/img/entity-popular (2).png';
import PEImage3 from '../assets/img/entity-popular (3).png';

import ReviewImage1 from '../assets/img/Review1.png';
import ReviewImage2 from '../assets/img/Review2.png';
import ReviewImage3 from '../assets/img/Review3.png';

const MockData = {

    // Video block
    video: {
        app: 'Ассоциация профессиональных риэлторов',
        title: 'Найдите или продайте объект',
        description: 'Агенты обмениваются реальной информацией об объектах только в профессиональной базе, без фейков и уток, вы можете найти из всех объектов рынка или продать свой вариант, для этого просто выберите себе агента проводника.',
        type: 1,
        startBtn: {
            text: 'Начать',
            link: '#go'
        },
        videoBtn: {
            text: 'Посмотреть видео',
            link: '#go'
        },
        image: VideoImage
    },

    // Search Tasks block
    search: {
        title: 'Выберите какую задачу вы хотите решить',
        bottomText: 'Powered by Reagent',
        reducedSize: false,
        input: {
            placeholder: 'Начните вводить название...',
        },
        btn: {
            text: 'Отправить оффер'
        }
    },

    // Promo Block
    promoItems: [
        {
            id: 100,
            image: PromoImage1,
            title: 'Выберите какую задачу вы хотите решить',
            description: 'Выбрав задачу которую вам надо выполнить, системе проще подобрать вам исполнителя. Так как учитывается сценарий задачи, локация объекта, опыт и рейтинг агента исполнителя.',
            button: {
                link: '#go',
                text: 'Начать'
            }
        },

        {
            id: 102,
            image: PromoImage2,
            title: 'Начните выбор объектов',
            description: 'Агент предоставит вам доступ в закрытую базу по недвижимости, где есть все реальные предложения без фейков от все агентов страны но под профилем вашего агента.',
            reverse: true
        },

        {
            id: 103,
            image: PromoImage3,
            title: 'Запланируйте просмотр',
            description: 'Получите подборку объектов и запланируйте их просмотр онлайн или оффлайн по видео и 3D туру.'
        }
    ],

    // Popular Entities block
    popularTitle: 'Некоторые объекты из закрытой профессиональной базы',
    popularBtn: {
        text: 'Просмотреть все объекты',
        link: '/objects/full'
    },
    popularEntities: [
        {
            label: 'Сочи',
            image: PEImage1,
            link: '/'
        },
        {
            label: 'Москва',
            image: PEImage2,
            link: '/'
        },
        {
            label: 'Краснодар',
            image: PEImage3,
            link: '/'
        },
    ],

    // Reviews Block
    reviewsTitle: 'Что говорят пользователи',
    reviews: [
        {
            image: ReviewImage1,
            name: 'Макаров Пётр',
            description: 'Выбирать из реальных объектов удобней, чем тратить время на популярные агрегаторы.'
        },
        {
            image: ReviewImage2,
            name: 'Проскурин Евгений',
            description: 'С помощью профессионального риэлтора всегда удобней продавать недвижимость'
        }
    ],

    // Search Tasks block
    secondSearch: {
        title: 'Выберите какую задачу вы хотите решить',
        bottomText: 'Powered by Reagent',
        reducedSize: true,
        input: {
            placeholder: 'Начните вводить название...',
        },
        btn: {
            text: 'Отправить оффер'
        }
    },
};

export default MockData;
