import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { selectEntitiesMapShow, toggleMap } from './entitiesSearch.slice';

import style from './style.module.less';

/**
 * Search field component
 * 
 * @param {object} props 
 * @returns 
 */
const FormSearch = (props) => {

  const {
    className = '',
    buttonClassName = '',
    inputClassName = '',
    wrapperClassName = '',
    label = '',
    btnText = '',
    placeholder = '',
    value = '',
    onChange = (data) => {},
    onSearch = (data) => {}
  } = props;

  const [inputValue, setInputValue] = useState(value);
  const isVisibleMap = useSelector(selectEntitiesMapShow);
  const dispatch = useDispatch();

  const handleChangeInput = (e) => {
    setInputValue(e.target.value);
    if( typeof onChange === 'function' ) {
      onChange(e);
    }
  }

  const handleClickButton = (e) => {
    setInputValue(e.target.value);
    if( typeof onSearch === 'function' ) {
      onSearch(e);
    }
  }

  // Show map
  const handleClickButtonMap = (e) => {
    dispatch(toggleMap());
  }

  let mapButtonClassList = [style.button, style.mapButton];

  if( isVisibleMap ) {
    mapButtonClassList.push(style.activeMap);
  }

  // Render
  return (
    <div className={[style.field, className].join(' ')}>
      
      {label.length >= 1 && (
        <label>{label}</label>
      )}

      <div className={[style.wrapper, wrapperClassName].join(' ')}>
        <input className={[style.input, inputClassName].join(' ')} type="text" value={inputValue} onChange={handleChangeInput} placeholder={placeholder} />
        <button className={[style.button, buttonClassName].join(' ')} onClick={handleClickButton}>{btnText}</button>
      </div>

      <button className={mapButtonClassList.join(' ')} onClick={handleClickButtonMap}>Карта</button>

    </div>
  )
}

export default FormSearch;