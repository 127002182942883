import style from './style.module.less';

import FormButton from '../../Form/Button';
import { getGlobalField, setGlobalField } from '../../../helpers';
import axios from 'axios';

function StepEnd(props) {

    const {
        onSubmit = (e) => { }
    } = props;

    function handleClickButton(e) {
        
        setGlobalField('searchTask_type', 0);
        setGlobalField('searchTask_city', 0);
        setGlobalField('searchTask_task', 0);
        setGlobalField('searchTask_phone', 0);
        setGlobalField('searchTask_isSended', 0);

        if (typeof onSubmit === 'function') {
            return onSubmit(e);
        }
    }

    const type = getGlobalField('searchTask_type');
    const city = getGlobalField('searchTask_city');
    const task = getGlobalField('searchTask_task');
    const phone = getGlobalField('searchTask_phone');
    const isSended = getGlobalField('searchTask_isSended');
    
    if( typeof task !== 'object' ) {
        return (<></>);
    }
    
    if( !type || !city || !task || !phone ) {
        console.log('ERROR!!!', type, city, task, phone);
        return (<></>);
    }
    
    setGlobalField('searchTask_isSended', 1);

    if( !isSended ) {
        console.log('Success', type, city, task, phone);

        axios.post(`${process.env.REACT_APP_API_URL}/api/public/v1/client_requests`, {
            phone: phone,
            city: city,
            agent_id: null,
            service: task
        }).then((response) => {

            console.log('Response:', response);

        });
        
    }

    return (
        <>
            <h2 className={style.title}>
                Запрос успешно отправлен! В ближайшее время с вами свяжется специалист, по вопросу: {task.title}
            </h2>

            <div className={style.form}>
                <FormButton onClick={handleClickButton} className={style.btn__form} isOuter={true}>
                    Новый запрос
                </FormButton>
            </div>
        </>
    );
}

export default StepEnd;