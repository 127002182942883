import style from './style.module.less';

import ReviewItem from './Item';
import Section from '../Section';

/**
 * Reviews react component
 * 
 * @param {object} props 
 * @returns 
 */
const Reviews = (props) => {
    const {
        reviews,
        title
    } = props;

    if( typeof reviews !== 'object' ) {
        return (<></>);
    }

    if( typeof reviews.length !== 'number' ) {
        return (<></>);
    }

    if( reviews.length <= 0 ) {
        return (<></>);
    }

    // Render
    return (
        
        <Section className={style.reviews}>

            <h2>{title}</h2>

            <div className={style.row}>
                {reviews.map((review, index) => {
                    return <ReviewItem data={review} key={index} />
                })}
            </div>

        </Section>
    );
}

export default Reviews;