import style from './style.module.less';
import { declOfNum } from '../../../helpers';
import NoneAvatar from '../../../assets/img/none.svg';
import { useDispatch } from 'react-redux';
import { selectAgentsSelected, setAgentsSelected, setAgentsSelectedAgent } from '../../AgentsSelected/agentsSelected.slice';
import { useSelector } from 'react-redux';

const AgentItem = (props) => {

    const {
        first_name = '',
        last_name = '',
        phone = '',
        review_rating = '',
        reviews_count = 0,
        www_link = '',
        avatar = null,
        className = '',
        onSelected = (e) => { return false; },
        isSelectable = false,
        agent = {}
    } = props;
    
    const dispatch = useDispatch();
    const agentSelected = useSelector(selectAgentsSelected);

    let photo = NoneAvatar;

    if( typeof avatar === 'object' && avatar && typeof avatar.small === 'string' ) {
        photo = avatar.small;
    }

    // Agent selecting
    const handleCardClick = (e) => {
        if( isSelectable === true && agentSelected === false ) {

            const agentJSON = JSON.stringify(agent);

            window.localStorage.setItem('agent', agentJSON);
            window.localStorage.setItem('selectedAgent', 'Y');
            
            dispatch(setAgentsSelectedAgent(agent));
            dispatch(setAgentsSelected(true));

            return onSelected(e, agent);
        }
    };

    // Render
    return (
        <div
            // href={www_link}
            onClick={handleCardClick}
            className={[style.agent, className].join(' ')}
            target="_blank"
            rel="noreferrer"
        >

            <div className={style.img}>
                <img src={photo} alt={first_name} />
            </div>

            <div className={style.body}>
                <div className={style.name}>
                    {first_name} {last_name}
                </div>
                <div className={style.phone}>
                    <span>+{phone}</span>
                </div>
                {review_rating >= 1 && (
                    <div className={style.rating}>
                        {review_rating}.0
                    </div>
                )}
                <div className={style.reviews}>
                    <a href={www_link} target="_blank" rel="noreferrer">{reviews_count} {declOfNum(reviews_count, ['отзыв','отзыва','отзывов'])}</a>
                </div>
            </div>

        </div>
    );
}

export default AgentItem;