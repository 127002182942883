import ImageEntity from '../assets/img/entity-popular (1).png';

const MockData = {

	// Entities
	entities: [
		{
			city: 'Сочи',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
		{
			city: 'Краснодар',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
		{
			city: 'Томбов',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
		{
			city: 'Томбов',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
		{
			city: 'Сочи',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
		{
			city: 'Сочи',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
		{
			city: 'Сочи',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
		{
			city: 'Сочи',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
		{
			city: 'Сочи',
			region: 'Мамайка',
			price: 16000000,
			street: 'Полтавская',
			house: '21',
			type: '2-х комнатная',
			area: 47,
			link: '/objects',
			image: ImageEntity
		},
	],

	// Search Tasks block
	search: {
		title: 'Выберите какую задачу вы хотите решить',
		bottomText: 'Powered by Reagent',
		reducedSize: false,
		input: {
				placeholder: 'Начните вводить название...',
		},
		btn: {
				text: 'Отправить оффер'
		}
	}
};

export default MockData;