import style from './style.module.less';
import { dateFormatted, declOfNum } from '../../../helpers';
import NoneAvatar from '../../../assets/img/none.svg';
import { useDispatch } from 'react-redux';
import { selectAgentsSelected, setAgentsSelected, setAgentsSelectedAgent } from '../../AgentsSelected/agentsSelected.slice';
import { useSelector } from 'react-redux';

const AgentListItem = (props) => {
    
    const {
        agency_name = '',
        first_name = '',
        last_name = '',
        index = 0,
        www_link = '',
        phone = '',
        review_rating = '',
        reviews_count = 0,
        avatar = null,
        last_review_date = new Date().toString(),
        agent = {},
        onSelected = (e, agent) => {}
    } = props;

    let {
        photo = NoneAvatar,
        last_review_comment = null
    } = props;

    const dispatch = useDispatch();
    const reviewDateFormatted = dateFormatted(last_review_date);
    const isSelectable = useSelector(selectAgentsSelected);

    if (typeof avatar === 'object' && avatar && typeof avatar.small === 'string') {
        photo = avatar.small;
    }

    if( typeof last_review_comment === 'string' && last_review_comment.length >= 180 ) {
        last_review_comment = last_review_comment.slice(0, 180) + '...';
    }

    // Agent selecting
    const handleCardClick = (e) => {

        if( isSelectable === false ) {

            e.preventDefault();
            const agentJSON = JSON.stringify(agent);

            window.localStorage.setItem('agent', agentJSON);
            window.localStorage.setItem('selectedAgent', 'Y');
            
            dispatch(setAgentsSelectedAgent(agent));
            dispatch(setAgentsSelected(true));

            return onSelected(e, agent);
        }
    };

    // Render
    return (
        <div key={`${first_name}_${last_name}_${index}`} className={style.agent} onClick={handleCardClick}>

            <a className={style.avatar} href={www_link} target="_blank" rel="noreferrer">
                <img src={photo} alt={`${first_name}_${last_name}`} />
            </a>

            <div className={style.properties}>
                <div className={[style.property, style.property_agency].join(' ')}>
                    {agency_name}
                </div>
                
                <a className={[style.property, style.property_name].join(' ')} href={www_link} target="_blank" rel="noreferrer">
                    {first_name} {last_name}
                </a>

                <a href={`tel:+${phone}`} className={[style.property, style.property_phone].join(' ')}>
                    +{phone}
                </a>
                
                {review_rating >= 1 && (
                    <div className={[style.property, style.property_rating].join(' ')}>
                        {review_rating}.0
                    </div>
                )}
                
                <div className={[style.property, style.property_reviews].join(' ')}>
                    <a href={www_link} target="_blank" rel="noreferrer">
                        {reviews_count} {declOfNum(reviews_count, ['отзыв', 'отзыва', 'отзывов'])}
                    </a>
                </div>
            </div>

            <div className={[style.map].join(' ')}>
                <div className={style.map_block}></div>
                {agency_name}
            </div>

            <div className={style.reviews}>

                {last_review_comment && (
                    <>
                        <a href={www_link} target="_blank" rel="noreferrer">Отзыв от {reviewDateFormatted}</a>
                        <p>
                            “{last_review_comment}”
                        </p>
                    </>
                )}

            </div>

            <div className={style.arrow}>
                <a href={www_link} target="_blank" rel="noreferrer">
                    <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.40081 14.3637L0.31416 22.0252C0.136899 22.2168 0.136899 22.5126 0.314161 22.7042L2.07067 24.6032C2.2686 24.8172 2.60685 24.8172 2.80478 24.6032L13.686 12.8395C13.8632 12.6479 13.8632 12.3521 13.686 12.1605L2.80478 0.396821C2.60685 0.182844 2.2686 0.182845 2.07067 0.396822L0.314163 2.29579C0.136901 2.48743 0.1369 2.78318 0.314163 2.97482L7.40087 10.6363L8.70356 12.1772C8.86111 12.3636 8.86111 12.6364 8.70355 12.8228L7.40081 14.3637Z" fill="black"/>
                    </svg>
                </a>
            </div>
        </div>
    );
}

export default AgentListItem;