import Filter from '../components/Filter';
import Entities from '../components/Entities';
import SearchTask from "../components/SearchTask";
import Agents from '../components/Agents';

import Mock from '../mocks/fullObjects.mock';
/**
 * Page with full objects list
 * URL: /objects/full
 * 
 * @param {object} props 
 * @returns 
 */
const FullObjects = (props) => {

  const {
		search,
    entities
	} = Mock;

  return (
    <>

      <Filter />

      <Agents />

      <Entities items={entities} />

      <SearchTask 
        title={search.title}
				bottomText={search.bottomText}
				input={ search.input }
				btn={ search.btn }
				reducedSize={search.reducedSize} 
      />

    </>
  );
}

export default FullObjects;