import { createSlice } from '@reduxjs/toolkit';

export const EntitiesSearchSlice = createSlice({
    name: 'entitiesSearch',
    initialState: {
        isVisible: false
    },
    reducers: {
        showMap: (state, action) => {
            state.isVisible = true;
        },
        hiddenMap: (state, action) => {
            state.isVisible = false;
        },
        toggleMap: (state, action) => {
            state.isVisible = !state.isVisible;
        }
    }
});

export const { showMap, hiddenMap, toggleMap } = EntitiesSearchSlice.actions;

export const selectEntitiesMapShow = state => state.entitiesSearch.isVisible;

export const EntitiesSearchSliceReducer = EntitiesSearchSlice.reducer;

export default EntitiesSearchSliceReducer;