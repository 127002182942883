import style from './style.module.less';

import Section from '../Section';
// import { Link } from 'react-router-dom';
import AgentListItem from './AgentListItem';

/**
 * Component for view Agents list
 * 
 * @param {object} props 
 * @returns 
 */
const AgentsList = (props) => {

	const {
		className = '',
		agents = []
	} = props;

	// Empty render if agents not found
	if (!agents || agents.length <= 0) {
		return (
			<></>
		);
	}

	// Render
	return (
		<Section className={[style.section, className].join(' ')}>

			<div className={style.header}>
				<div className={[style.header_item, style.item_1].join(' ')}>
					Агенты
				</div>
				<div className={[style.header_item, style.item_2].join(' ')}>
					Локация
				</div>
				<div className={[style.header_item, style.item_3].join(' ')}>
					Отзыв Клиента
				</div>
			</div>
			<div className={style.agents}>
				{agents.map((agent, index) => (
					<AgentListItem
						agency_name = {agent.agency_name}
						first_name = {agent.first_name}
						last_name = {agent.last_name}
						index = {index}
						www_link = {agent.www_link}
						phone = {agent.phone}
						review_rating = {agent.review_rating}
						reviews_count = {agent.reviews_count}
						last_review_comment = {agent.last_review_comment}
						avatar = {agent.avatar}
						key={agent.id}
						last_review_date={agent.last_review_date}
						agent={agent}
					/>
				))}

			</div>

		</Section>
	)
}

export default AgentsList;